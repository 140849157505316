<script setup lang="ts">
import { RouterLink } from 'vue-router'
import { computed, useAttrs, type HTMLAttributes, withDefaults } from 'vue'
import { Primitive, type PrimitiveProps } from 'radix-vue'
import { cn } from '../utils'
import * as ButtonPresenters from './presets'
import { provideButtonPresenter, relayButtonPreseterConfig, type ButtonPresenter, type ButtonPresenterConfig } from './types'
import type { RouteLocationRaw } from 'vue-router'

interface Props extends PrimitiveProps {
  preset?: keyof typeof ButtonPresenters | ButtonPresenter
  class?: HTMLAttributes['class']
  to?: string | RouteLocationRaw
}

const props = withDefaults(defineProps<Props & Partial<ButtonPresenterConfig>>(), {
  as: 'button',
  preset: 'standard',
  size: 'default',
})

const preseterConfig = relayButtonPreseterConfig(props)
const presenter = provideButtonPresenter(computed(() => {
  if (typeof props.preset != 'string') {
    return props.preset
  }

  return ButtonPresenters[props.preset]
}))

const attrs = useAttrs()
</script>

<template>
  <Primitive :to="to" :as="attrs.disabled ? 'button' : props.to ? RouterLink : as" :as-child="asChild" :class="cn(presenter?.root(preseterConfig), props.class)">
    <slot></slot>
  </Primitive>
</template>
