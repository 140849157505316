import { createApp } from 'vue'
import AppTemplate from './AppTemplate.vue'

import './assets/style.css'

import '~/vendor/fontawesome'
import '~/vendor/coursewares'
import '~/vendor/selectize'
import './assets/stylesheets'

const app = createApp(AppTemplate)

import * as initializers from './initializers'
for (const key in initializers) {
  initializers[key](app)
}

app.mount('#app')
